.accordion-button {
  width: 100%;
  margin: 0;
  background: $background;
  color: $text;
  text-align: left;
  border-bottom: 1px solid #9e9e9e;
  padding-left: 0;
  padding-right: 0;
  font-size: 1.3em;
  position: relative;

  &:active, &:focus, &:hover {
    background: $background;
  }

  span {
    line-height: 10px;
    height: 10px;
    color: $text;
    margin: auto 0;
    //float: right;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 0.9em;
  }
}

.accordion-body {
  margin-left: 3%;
}
