$loader-bg: #FFFFFF;
$loader-fg: $primary;

.loader-container {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 200px;
  overflow: hidden;
  width: 100%;
}

.loader {
  animation: load 1.1s infinite linear;
  border: 1.1em solid $loader-bg;
  border-left: 1.1em solid $loader-fg;
  border-radius: 50%;
  font-size: 10px;
  height: 10em;
  margin: 60px 60px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 10em;

  &::after {
    border-radius: 50%;
    height: 10em;
    width: 10em;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
