footer {
  margin: 0;
  text-align: center;
  width: 100%;
  color: $text;
  display: flex;
  height: 8%;

  .vpc{
    height: 50px;
    padding-right: 1.5%;
  }

  .wpi{
    height: 50px;
  }

  .sponsorship {
    text-align: left;
    margin: 0;
    width: 30%;
    padding-left: 1.5%;
  }

  .sandbox-parts {
    text-align: center;
    margin: 0;
    width: 40%;
    padding: 0;
  }

  .credits {
    text-align: right;
    margin: 0;
    width: 30%;
    padding-right: 1.5%;
  }

  .cc {
    pointer-events: none;
    vertical-align: middle;
  }
}
