svg.bar {
  width: 100%;
  max-height: 100%;
  color: $text;

  path {
    stroke: $text;
  }

  text {
    fill: $text;
  }

  line {
    stroke: $text;
  }
}
