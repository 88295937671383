svg.donut {
  width: 100%;
  max-height: 100%;

  .arc text{
    text-anchor: middle;
  }

  .arc path {
  }

  .alwaystext {
    pointer-events: none;
  }
}

.row{
  margin: 0;
}
