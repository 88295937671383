.leftrightwrapper {
  margin: 0 0 15px;

  > label {
    display: block;
  }
}

.hovertext {
  pointer-events: none;
}

.color-wrapper {
  margin-bottom: 10px;

  > label {
    color: $subtletext;
  }

  .buffer {
    height: 3rem;
    font-size: 1em;
  }

  > input[type='text'] {
    margin-bottom: 0;
    color: $text;
    width: 50%;

    &:focus {
      border-color: $primary !important;
      box-shadow: 0 0 !important;
    }
  }

  > input[type='color'] {
    margin-right: 15px;
  }
}
