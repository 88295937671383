input[type=checkbox] {

  & + label {
    color: $subtletext;
  }

  &:checked + label:before {
    border-bottom-color: $primary !important;
    border-right-color: $primary !important;
  }
}
