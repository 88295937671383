body {
  background-color: $background;
}

.controls, .visual, .intro, .keyContainer, .majorSelect,.graphTitle {
  position: relative;
}

/*
.majorSelect {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
}
*/

.row {
  display: flex;
  flex-wrap: wrap;
}

.controls {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.graphTitle {
  visibility: hidden;
  margin-top: 0;
}

.visual {
  margin: 1% 1% 2% 1%;
}

.visual-title, .visual-description {
  display: inline-block;
  line-height: 1;
  word-wrap: break-word;
  text-align: center;
  width: 100%;
}

.blurb {
  display: block;
  width: 100%;
  min-height: 100%;
  padding: 2% 2% 2% 2%;
  overflow-y: auto;
  overflow-x: hidden;
}

.keyContainer {
  margin-top: 3.2em;
  display: none;
}
.key {
  max-height: 100%;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

#keyTitle {
  position: absolute;
  top: -1.5em;
  left: -0.1em;
}

.visual-title {
  margin: 0 1% 2% 1%;
}

.visual-description {
  margin: 2% 1% 2% 1%;
}

.column1 {
  display: flex;
  flex: 0 0 23.5%;
  width: 23.5%;
  height: 77%;
  flex-direction: column;
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.column2 {
  display: flex;
  flex: 0 0 73.5%;
  flex-direction: column;
  width: 73.5%;
  height: 77%;
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.visualColumn {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

.download {
  position: relative;
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 7%;
  margin-top: 1%;
  padding-left: 1.5%;
  padding-right: 1.5%;
}

