.grid {
  .grid-element {
    flex: 0 0 20%;
    max-width: 20%;
    padding: 1% 1%;
  }

  .block {
    background: $primary;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 10px;
    transition: .7s;

    img {
      width: 100%;
    }

    object {
      pointer-events: none;
      width: 100%;
    }

    .name {
      color: $text;
      display: flex;
      font-size: 1.5rem;
      justify-content: center;
      padding: 5px;
      text-align: center;
    }

    &:hover,
    &:focus {
      transform: scale(1.05);
    }
  }

  div {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
