%button {
  background: $primary;
  border: 0;
  color: $text;
  padding: 10px 28px;
  font-size: 14px;

  // Makes text on buttons non-interactive
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover{
    text-decoration: none;
    background-color: $secondary;
    transition: background 0.8s;
    color: $text;
  }

  &:focus {
    background-color: $primary;
    transition: background 0.8s;
  }
}

button, .left-button, .right-button {
  @extend %button;
}

.button {
  // @extend %button;
}
