header {
  background: $background;
  display: flex;
  left: 0;
  top: 0;
  position: static;
  width: 100%;
  height: 7%;
  z-index: 20;
  padding-left: 1.5%;
  font-size: 15px;
  text-align: center;
  align-items: center;
}

.color-label
{
  display: block;
  margin-bottom: 0;
}
