.list {
  .list-row {
    background: $white;
    border-radius: 10px;
    margin-top: 30px;
    position: relative;
    width: 100%;

    p {
      display: inline-block;
      margin: 0;
      padding: 25px;
    }

    .data-set {
      width: 30%;
    }

    .type {
      width: 20%;
    }

    .title {
      width: 50%;
    }

    button {
      background: $secondary;
      border: 0;
      border-radius: 10px;
      bottom: 0;
      color: $white;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      &:hover {
        opacity: 100;
      }
    }
  }
}
